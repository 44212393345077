import React, { useState, useEffect } from 'react';
import finallogo from '../asset/finallogo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import gif from '..//asset/search.gif'

const Dashboard = () => {
    const SHAREPOINT_URI = process.env.REACT_APP_SharePoint_URI;
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [attachments, setAttachments] = useState({});
    const [userEmail, setUserEmail] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const [trainingPPT, setTrainingPPT] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            const token = decryptToken();
            if (!token) {
                console.error('No authentication token found or decryption failed');
                return;
            }

            try {
                const response = await fetch(
                    `${SHAREPOINT_URI}/_api/web/currentuser`,
                    {
                        headers: {
                            Accept: 'application/json;odata=verbose',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`Error fetching user data: ${response.status} - ${response.statusText}`);
                }

                const userData = await response.json();
                setUserEmail(userData.d.Email);
                setCurrentUser(userData.d); // Store user data

                fetchListData(token, userData.d.Email);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        const fetchListData = async (token, userEmail) => {
            try {
                const response = await fetch(
                    `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items`,
                    {
                        headers: {
                            Accept: 'application/json;odata=verbose',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setDisplayData(data.d.results);

                data.d.results.forEach((item) => {
                    fetchexcel(item.Id, token);
                });
            } catch (error) {
                console.error('Error fetching SharePoint list data:', error);
            }
        };

        const fetchexcel = async (itemId, token) => {
            try {
                const response = await fetch(
                    `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items(${itemId})/AttachmentFiles`,
                    {
                        headers: {
                            Accept: 'application/json;odata=verbose',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`Error fetching excel for item ${itemId}: ${response.status}`);
                }

                const data = await response.json();
                setAttachments((prevAttachments) => ({
                    ...prevAttachments,
                    [itemId]: data.d.results,
                }));
            } catch (error) {
                console.error('Error fetching excel:', error);
            }
        };

        const fetchDocuments = async () => {
            const token = decryptToken();
            if (!token) {
                console.error('No authentication token found or decryption failed');
                return;
            }
            try {
                const response = await fetch(
                    `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_1}')/items?$select=Id,Title,FileRef,FileLeafRef`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json;odata=verbose',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`Error fetching documents: ${response.status} - ${response.statusText}`);
                }

                const data = await response.json();
                setTrainingPPT(data.d.results);
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
        };

        const decryptToken = () => {
            const encryptedToken = localStorage.getItem('authToken');
            const encryptionKey = localStorage.getItem('encryptionKey');
            if (!encryptedToken || !encryptionKey) {
                return null; // Return null if token or key is missing
            }
            const bytes = CryptoJS.AES.decrypt(encryptedToken, encryptionKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        };

        fetchUserData();
        fetchDocuments();
    }, []);

    return (
        <>
            <div className="w3-top">
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container fluid>
                        <Navbar.Brand href="/newtraningRecord">
                            <img src={finallogo} height='50' width='70' alt="logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                                <Nav.Link href="/newtraningRecord" style={{ borderRadius: '0.5rem', color: '#fff', background: '#932c35' }} >
                                    <i className="fa-solid fa-plus"></i> Create Training
                                </Nav.Link>
                            </Nav>
                            <Nav className="me-auto my-2 my-lg-0">
                                <div className="nav-link mega-menu-toggle" style={{ borderRadius: '0.5rem', background: '#932c35', color: '#fff' }}>
                                    Training PPTs
                                </div>
                                <div className="mega-menu">
                                    {trainingPPT.map((powerPoint) => {
                                        // Constructing the URL with more parameters to hide SharePoint UI elements
                                        const wopiFrameUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(powerPoint.FileRef)}&action=embedview&hidetoolbar=true&ui=embed`;
                                        return (
                                            <div key={powerPoint.FileRef}>
                                                <a
                                                    className="cstm-links ms-3"
                                                    href={wopiFrameUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {powerPoint.FileLeafRef}
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>

                            </Nav>
                            <Form className="d-flex me-auto">
                                <Form.Control
                                    className="form-control mr-sm-2"
                                    type="search"
                                    placeholder="Enter SiteName"
                                    aria-label="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </Form>
                            <Nav className="my-2 my-lg-0" style={{ maxHeight: '' }} navbarScroll>
                                <Nav.Link
                                    className='my-2 my-lg-0 ms-3'
                                    href="/Logout"
                                    style={{ color: '#fff', background: '#932c35', borderRadius: '5px' }}  // Add borderRadius here
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={currentUser ? `${currentUser.Title} Want's To Logout!` : "Want To Logout!"}
                                >
                                    <i className="fa-solid fa-arrow-right-from-bracket fa-lg"></i> Logout
                                </Nav.Link>
                            </Nav>



                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>

            <div className="-r-22" style={{ height: '6rem' }}>
                <div className="-a-23"></div>
            </div>

            <section className="intro h-100">
                <div className="mask d-flex align-items-center h-100">
                    <div className="container">
                        <div className="title">
                            <h2>Training Detail</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="card shadow-2-strong" style={{ backgroundColor: "#f5f7fa" }}>
                                    <div className="card-body">
                                        {/* Display current user data */}

                                        <div className="table-responsive" style={{ height: 'auto' }}>
                                            <table className="table table-fixed mb-0">
                                                <thead>
                                                    <tr style={{ borderBottom: "1px solid" }}>
                                    
                                                        <th scope="col" className="table-column-width1"> Date</th>
                                                        <th scope="col" className="table-column-width1">Site Name</th>
                                                        <th scope="col" className="table-column-width1">Location</th>
                                                        <th scope="col" className="table-column-width1">TrainerName</th>
                                                        <th scope="col" className="table-column-width1">TrainingTopic</th>
                                                        <th scope="col" className="table-column-width1">Duration</th>
                                                        <th scope="col" className="table-column-width1">Attachments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
    {displayData && displayData.length > 0 ? (
        displayData.filter(
            (training) =>
                currentUser &&
                training.AuthorId === currentUser.Id &&
                training.SiteName &&
                training.SiteName.toString().toLowerCase().includes(searchQuery.toLowerCase())
        ).length > 0 ? (
            displayData
                .filter(
                    (training) =>
                        currentUser &&
                        training.AuthorId === currentUser.Id &&
                        training.SiteName &&
                        training.SiteName.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((training) => (
                    <tr key={training.Id} style={{marginBottom:'1rem'}}>
                       
                        <td className="table-column-width">{training.Date}</td>
                        <td className="table-column-width">{training.SiteName}</td>
                        <td className="table-column-width">{training.Location}</td>
                        <td className="table-column-width">{training.TrainerName}</td>
                        <td className="table-column-width">{training.TrainingTopic}</td>
                        <td className="table-column-width">{training.Duration}</td>
                        <td className="table-column-width">
                            {attachments[training.Id] && attachments[training.Id].length > 0 ? (
                                <ul>
                                    {attachments[training.Id].map((attachment, index) => {
                                        const fileExtension = attachment.FileName.split('.').pop().toLowerCase();
                                        const isOfficeDocument = ['docx', 'xlsx', 'pptx', 'xls', 'doc'].includes(fileExtension);

                                        return (
                                            <li key={attachment.FileName} style={{ border: '1px solid', marginBottom: '0.2rem' }}>
                                                {isOfficeDocument ? (
                                                    <a
                                                        className="cstm-links"
                                                        href={`${process.env.REACT_APP_SharePoint_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${attachment.ServerRelativeUrl}&action=view`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ borderRadius: '0.5rem' }}
                                                    >
                                                        {attachment.FileName}
                                                    </a>
                                                ) : (
                                                    <a
                                                        className="cstm-links"
                                                        href={`${process.env.REACT_APP_SharePoint_URI}${attachment.ServerRelativeUrl}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ borderRadius: '0.5rem' }}
                                                    >
                                                        {attachment.FileName}
                                                    </a>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                'No attachments'
                            )}
                        </td>
                    </tr>
                ))
        ) : (
            <tr>
                <td colSpan="8" style={{ textAlign: 'center' }}>
                   <img src={gif}></img> No detail found
                </td>
            </tr>
        )
    ) : (
        <tr>
            <td colSpan="8" style={{ textAlign: 'center' }}>
            <img src={gif}></img> No detail found<br/>
          <Link to='/newtraningRecord'>Please Create New Training</Link>

            </td>
        </tr>
    )}
</tbody>



                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Dashboard;