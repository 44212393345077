import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes,Navigate  } from 'react-router-dom';
import NewTraningRecord from './Component/Record/newtraningRecord';
import Auth from './Auths/Auth';
import Dashboard from './Component/Dashboard';
import LogoutButton from './Auths/Logout';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route>
          <Route path='/' element={<Auth/>} />
          <Route path='/Logout' element={<LogoutButton/>}/>
          <Route path='/newtraningRecord' element={<NewTraningRecord/>}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
          <Route path="*" element={<Navigate to="/dashboard" replace />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
