import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import finallogo from "../../asset/finallogo.png";
import bgimage from "../../asset/Facility.jpg";
import { Modal, Button } from "react-bootstrap";
import check from "../../asset/check-mark-icon-png.png";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import CryptoJS from 'crypto-js';



const CreateTraining = () => {
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [files, setFiles] = useState([[], [], []]);
  const [siteData, setSiteData] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [location, setLocation] = useState("");
  const [trainerName, setTrainerName] = useState([]);
  const [selectedTrainerName, setSelectedTrainerName] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [topic, setTopic] = useState("");
  const [time, setTime] = useState("");
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const [showInput, setShowInput] = useState(false); // State to control input visibility
  const [showSiteInput, setSiteShowInput] = useState(false); // State to control input visibility

  const SHAREPOINT_URI = process.env.REACT_APP_SharePoint_URI

  const navigate = useNavigate();




  const fetchRequestDigest = async () => {
    const token = decryptToken();

    if (!token) {
      alert("No authentication token found");
      return;
    }

    try {
      const response = await axios.post(`${SHAREPOINT_URI}/_api/contextinfo`, null, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.d.GetContextWebInformation.FormDigestValue;
    } catch (error) {
      console.error(
        "Error fetching request digest:",
        error.response?.data || error.message
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      console.warn("Form is already being submitted.");
      return;
    }

    setIsSubmitting(true); // Set submitting flag to true immediately

    try {
    const token = decryptToken();
      if (!token) {
        console.error("No authentication token found");
        return;
      }

      const digestValue = await fetchRequestDigest();
      if (!digestValue) {
        console.error("Failed to obtain request digest");
        return;
      }

      const endpoint = `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items`;
      const item = {
        Date: date,
        SiteName: selectedSite,
        Location: location,
        TrainerName: selectedTrainerName,
        TrainingTopic: topic,
        Duration: `${time} Minutes`,
      };

      const headers = {
        Accept: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose",
        "X-RequestDigest": digestValue,
        Authorization: `Bearer ${token}`,
      };

      const metadata = {
        type: `SP.Data.${process.env.REACT_APP_SharePoint_List_1}ListItem`,
      };

      const data = {
        __metadata: metadata,
        ...item,
      };

      const response = await axios.post(endpoint, JSON.stringify(data), { headers });
      setShow(true);

      const itemId = response.data.d.ID;
      const allFiles = files.flat();

      if (allFiles.length > 0) {
        await uploadFiles(allFiles, itemId, token, digestValue);
      }

      const matchingPPT = trainingPPT.find((ppt) => ppt.Title === topic);
      if (matchingPPT) {
        setSelectedAttachment(matchingPPT.FileRef);
      } else {
        setSelectedAttachment('No attachment matched');
      }

      const itemData = {
        Title: item.TrainingTopic,
        Description: `Trainer: ${item.TrainerName}, Location: ${item.Location}, Time: ${item.Duration}, Date: ${item.Date}`,
        attachment: matchingPPT ? matchingPPT.FileRef : 'No attachment matched',
      };

      await sendEmailNotification(itemData);

    } catch (error) {
      console.error("Error adding item:", error);
    } finally {
      setIsSubmitting(false); // Reset submitting flag
    }
  };

  const fetchFileContent = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result.split(',')[1]); // Get Base64 part
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching file content:", error);
      return null;
    }
  };

  const sendEmailNotification = async (itemData) => {
    let base64Attachment = null;

    if (itemData.attachment !== 'No attachment matched') {
      base64Attachment = await fetchFileContent(itemData.attachment);
    }

    const emailData = {
      to: `${process.env.REACT_APP_SharePoint_Email}`,
      subject: `New item created: ${itemData.Title}`,
      body: `A new item was created with the following details:\n\nTitle: ${itemData.Title}\nDescription: ${itemData.Description}`,
      attachment: base64Attachment ? `data:application/octet-stream;base64,${base64Attachment}` : null
    };

    try {
      const response = await axios.post("http://localhost:3001/send-email", emailData);
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const uploadFiles = async (files, itemId, token, digestValue) => {

    for (const file of files) {
      if (file && file instanceof File) {
        const endpoint = `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items(${itemId})/AttachmentFiles/add(FileName='${file.name}')`;

        try {
          const fileArrayBuffer = await file.arrayBuffer();

          const headers = {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/octet-stream",
            "X-RequestDigest": digestValue,
          };

          const response = await axios.post(endpoint, fileArrayBuffer, {
            headers,
          });
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
        }
      } else {
        console.error("File is not valid:", file);
      }
    }
  };

  // Fetch SharePoint list data for the site dropdown
  const fetchListData = async (url, accumulatedData = []) => {
    const token = decryptToken();
    if (!token) {
      console.error('No authentication token found');
      return;
    }

    try {
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json;odata=verbose',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const combinedData = [...accumulatedData, ...data.d.results];
      if (data.d.__next) {
        await fetchListData(data.d.__next, combinedData);
      } else {
        // Set state when all pages are fetched
        setSiteData(combinedData);
        setTrainerName(combinedData);
      }
    } catch (error) {
      console.error('Error fetching SharePoint list data:', error);
    }
  };

  useEffect(() => {
    const initialUrl = `${process.env.REACT_APP_SharePoint_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_2}')/items?$top=100`;
    fetchListData(initialUrl);
  }, []);


  const handleSiteLocation = (event) => {
    const selectedSiteName = event.target.value; // Get the selected site name from the dropdown
  
    if (selectedSiteName === 'add-new') {
      setSiteShowInput(true); // Show the input field if "add-new" is selected
      setSelectedSite(''); // Reset selectedSite when showing input
    } else {
      setSiteShowInput(false); // Hide the input field when a valid site is selected
      setSelectedSite(selectedSiteName); // Set the selected site name
    }
   
    // Find the selected site data in siteData array based on the selected site name
    const selectedSiteData = siteData.find((site) => site.Title === selectedSiteName);
    if (selectedSiteData) {
      setLocation(selectedSiteData.field_1); // Set the location based on the selected site data
    }
  };
  const handleCustomSiteInputChange =(e)=>{
    const newSite = e.target.value;
    setSelectedSite(newSite)

  }
  const handleSiteLabelClick = () => {
    setSiteShowInput(false); // Close the input field and show the dropdown
  };
 

  const handleTrainerName = (e) => {
    const value = e.target.value;
    setSelectedTrainerName(value); // Ensure this is set to the selected value

    if (value === 'add-new') {
      setShowInput(true);
      setSelectedTrainerName(''); // Reset selectedTrainerName when showing input
    } else {
      setShowInput(false);
      setSelectedTrainerName(value);
    }
  };
  const handleLabelClick = () => {
    setShowInput(false); // Close the input field and show the dropdown
  };
  

  const handleTrainerNameInputChange = (event) => {
    const newTrainerName = event.target.value;
    setSelectedTrainerName(newTrainerName);
  };


  const handleFileChange = (e, index) => {
    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length === 0) return;

    // Define maximum file sizes (in bytes) for different types
    const maxImageSize = 0.3 * 1024 * 1024; // 0.5 MB for images

    const validFiles = [];

    // Function to rename file
    const renameFile = (file, newName) => {
      return new File([file], newName, { type: file.type });
    };

    for (const file of selectedFiles) {
      let maxFileSize;

      // Determine maximum file size based on file type
      if (file.name.match(/\.(jpg|jpeg|png|pdf|webp|PNG)$/i)) {
        maxFileSize = maxImageSize;
      } else {
        alert('Unsupported file type. Please upload a valid file.');
        e.target.value = '';
        return;
      }

      if (file.size > maxFileSize) {
        alert(`File size exceeds the ${maxFileSize / (1024 * 1024)} MB limit. Please upload a smaller file.`);
        e.target.value = '';
        return;
      }

      // Check if index is 0 to rename all files
      let newName = file.name;
      if (index === 0) {
        const timestamp = Date.now();
        const extension = file.name.split('.').pop();
        newName = `TrainingSheet${timestamp}.${extension}`;
      }

      // Rename the file if needed
      const renamedFile = renameFile(file, newName);
      validFiles.push(renamedFile);
    }

    // Update files array with valid and renamed files
    const newFiles = [...files];
    newFiles[index] = validFiles;
    setFiles(newFiles);
  };

  const handleOk = () => {
    navigate("/dashboard");
  };
  const calculateDifference = () => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const diffInMinutes = `${end - start}`;
    const diffInMs = diffInMinutes / 60000

    if (diffInMs >= 10) {
      setTime(diffInMs);

    } else if (diffInMs <= 10) {
      alert('Training time must be 10 or Greater than Minutes ');
      setEndTime('')
      setTime("");

    }


  };
  useEffect(() => {
    calculateDifference();
  }, [startTime, endTime]);


  // shrpoint PPT document
  const [trainingPPT, setTrainingPPT] = useState([]);

  const fetchDocuments = async () => {
    const token = decryptToken();
    if (!token) {
      console.error('No authentication token found');
      return;
    }
    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_1}')/items?$select=Id,Title,FileRef,FileLeafRef`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json;odata=verbose',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setTrainingPPT(data.d.results);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
    
  };
  const decryptToken = () => {
    const encryptedToken = localStorage.getItem('authToken');
    const encryptionKey = localStorage.getItem('encryptionKey');
    if (!encryptedToken || !encryptionKey) {
        return null; // Return null if token or key is missing
    }
    const bytes = CryptoJS.AES.decrypt(encryptedToken, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    fetchDocuments();
  }, []);


  return (
    <>
      <div className="w3-top">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Brand href="/newtraningRecord">
              <img src={finallogo} height='50' width='70' alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">

              <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '' }} navbarScroll>
                <Nav.Link
                  className=' my-2 my-lg-0 ms-3'
                  href="/Logout"
                  style={{ color: '#fff', background: '#932c35', borderRadius: '5px' }}  // Add borderRadius here
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={currentUser ? `${currentUser.Title} Want's To Logout!` : "Want To Logout!"}
                >
                  <i className="fa-solid fa-arrow-right-from-bracket fa-lg"></i> Logout
                </Nav.Link>
              </Nav>



            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div
        className=""
        style={{
          backgroundImage: `url(${bgimage})`,
        }}
      >

        <div className="-r-22" style={{ height: '50px' }}><div className="-a-23"></div></div>
        <div className="container" style={{ backgroundColor: '#fff', maxWidth: '850px', overflow: 'auto', border: 'solid ', position: 'relative' }}>
          <div className="row mb-3" style={{ height: '9rem', backgroundColor: '#e5d9da' }}>
            <div style={{ padding: '1rem -0.25rem 3.125rem 3.125rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                <img className="create-logo" alt="img" src={finallogo} height='70' width='70' style={{ marginTop: '50%' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <h2 className="heading-main" style={{ color: '#ffffff', textAlign: 'center', margin: 0 }}>
                  SK Training
                </h2>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="" style={{ paddingLeft: "1.25rem" }}>
              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label
                  className="form-label detail-title" style={{ float: 'inline-start' }}
                >
                  1. Date:
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>
                <input
                  type="date"
                  className="form-control "
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  id="MaintenanceDate"
                  name="MaintenanceDate"
                  required
                />
              </div>
              <div
  className="col-md-11 form-group"
  style={{ padding: "2.1875rem 2.25rem" }}
>
  <label className="form-label detail-title" style={{ float: 'inline-start' }} onClick={handleSiteLabelClick}>
    2. Select Site Name:{" "}
    <span className="tx-danger" style={{ color: "#dc3545" }}>
      *
    </span>
  </label>
  {!showSiteInput ? (
    <select
      className="form-control"
      value={selectedSite}
      onChange={handleSiteLocation}
      id="SiteName"
      name="SiteName"
      required
    >
      <option value="">--Select Site--</option>
      <option value="add-new" style={{ fontWeight: '700' }}>Other</option>
      {siteData.map((site, index) => (
        <option key={index} value={site.Title}>
          {site.Title}
        </option>
      ))}
    </select>
  ) : (
    <input
      type="text"
      className="form-control"
      placeholder="Enter New Site Name" // Updated placeholder text
      onChange={handleCustomSiteInputChange}
      required
    />
  )}
</div>

              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>
                  3. Location:{" "}
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control "
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  id="location"
                  placeholder="Location"
                  
                  required
                />
              </div>

              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label className="form-label detail-title" style={{ float: 'inline-start' }}  onClick={handleLabelClick}>
                  4. Trainer Name:
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>

                </label>
                {!showInput ? (
                  <select
                    type="text"
                    className="form-control"
                    onChange={handleTrainerName}
                    placeholder="Enter Trainer Name"
                    required
                  >
                    <option value="">--Select Trainer Name--</option>
                    <option value="add-new" style={{ fontWeight: '700' }} >Other</option>

                    {trainerName
                      .filter((site) => site.field_2 && site.field_2.trim() !== '')
                      .map((site, Id) => (
                        <option key={Id} value={site.field_2}>
                          {site.field_2}
                        </option>
                      ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter New Trainer Name"
                    onChange={handleTrainerNameInputChange}
                    required
                  />
                )}

              </div>
              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>
                  5. Training Topic:
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>
                <select
                  type="text"
                  className="form-control "
                  onChange={(e) => setTopic(e.target.value)}
                  placeholder="Enter Training Topic"
                  required
                >
                  <option value="">Select Training</option>
                  {trainingPPT.map((doc) => (
                    <option key={doc.FileRef} value={doc.Title}>
                      {doc.FileLeafRef}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-11 form-group" style={{ padding: "2.1875rem 2.25rem" }}>
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>
                  6. Training Duration:{" "}
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>


                <div className="col-md-5 form-group">

                  <label className="form-label detail-title" style={{ float: 'inline-start' }}>Start Time</label>

                  <input
                    type="time"
                    value={startTime}
                    className="form-control col-md-4"
                    onChange={(e) => setStartTime(e.target.value)}
                    required
                  />

                  <label className="form-label detail-title" style={{ float: 'inline-start' }}>End Time</label>

                  <input
                    type="time"
                    value={endTime}
                    className="form-control col-md-4 "
                    onChange={(e) => setEndTime(e.target.value)}
                    required
                  />
                  <label className="form-label detail-title" style={{ float: 'inline-start' }}>Total Duration</label>

                  <input
                    type="text"
                    value={`${time} Minutes`}
                    className="form-control mt-3"
                    onChange={(e) => setTime(e.target.value)}
                    readOnly
                    required
                  >

                  </input>
                </div>
              </div>
              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}>
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>7. Training Sheet <span className="tx-danger" style={{ color: "#dc3545" }}>
                  <small style={{ fontSize: '0.8rem' }}>(Max size: 300KB/IMG or pdf)</small>
                </span></label>

                <input
                  type="file"
                  multiple
                  accept="image/*,.pdf"
                  className="form-control"
                  onChange={(e) => handleFileChange(e, 0)}
                  required
                />
              </div>

              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}>
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>9. Training Pictures <span className="tx-danger" style={{ color: "#dc3545" }}>
                  <small style={{ fontSize: '0.8rem' }}>(Max size: 300KB/IMG)</small>
                </span></label>

                <input
                  type="file"
                  multiple
                  accept="image/*"
                  className="form-control"
                  onChange={(e) => handleFileChange(e, 1)}
                  required
                />
              </div>

              <div
                className="text-center"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "#932c35",
                    width: "150px",
                  }}
                >
                  Create
                </button>
              </div>
            </div>
          </form>
          <Modal show={show}>
            <Modal.Header>
              <Modal.Title>Success</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img
                  src={check}
                  alt="Success"
                  style={{ height: "100px", marginBottom: "20px" }}
                />
                <p>Training Record successfully!</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleOk}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default CreateTraining;

